import { React } from "react"
import { Container, Row } from "react-bootstrap"
import AcademicBox from "./AcademicBox"


const AcademicList = [
    {
        'School': 'Simon Fraser University (SFU)',
        'date' : 'May 2018 - May 2021',
        'program' : 'Bachelor of Science (BSc)',
        'descript': 'Major in Computer Science, Minor in Statistic'
    },
    {
        'School': 'Fraser International College (FIC)',
        'date' : 'Sep 2016 - Apr 2018',
        'program' : 'UNIVERSITY TRANSFER PROGRAM STAGE II : Computing Science'
    }
]

const Academic = () => {
    return (
        <Container>
            <Row className={'h-70 mx-5 align-items-center fs-5 justify-content-center'}>
                {
                    AcademicList.map((eachAcademic, index) =><AcademicBox key={index} academic={eachAcademic}/>)
                }
            </Row>
        </Container>
    )   
}





export default Academic 