import React from 'react'
import { Icon } from '@iconify/react';
import { Col, Container, Row } from 'react-bootstrap'

const Contact = () => {
    return (
        <Container fluid id={'contact'} className={'bg-dark text-white'}> 
            <Container fluid={'lg'} className={'h30page'}>
                <Row className={'h-30 mx-5 align-items-center fs-2 fw-bold justify-content-center'}>
                    Contact
                </Row>
                <Row className={'h-70 mx-2 align-items-center fs-2 fw-bold justify-content-center'}>
                    <Col md={4} className={'my-5'}>
                        <a href="https://www.linkedin.com/in/justinsiu17/">
                            <Row className='h-75 justify-content-center'>
                                <Icon icon="logos:linkedin-icon" className='align-items-center w-auto h-100'/>
                            </Row>
                            <Row className='h-25 fs-5 justify-content-center'>
                                justinsiu17
                            </Row>
                        </a>
                    </Col>
                    <Col md={4}>
                        <a href="https://github.com/jusiu17/">
                            <Row className='h-75 justify-content-center'>
                                <Icon icon="icon-park:github" className='align-items-center w-auto h-100'/>
                            </Row>
                            <Row className='h-25 fs-5 justify-content-center'>
                                jusiu17
                            </Row>
                        </a>
                    </Col>
                    <Col md={4}>
                        <Row className='h-75 justify-content-center'>
                            <Icon icon="clarity:email-solid" className='align-items-center w-auto h-100'/>
                        </Row>
                        <Row className='h-25 fs-5 justify-content-center'>
                                jussiu17@gmail.com
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Contact
