import React from 'react'
import SchoolPrj from './prj/SchoolPrj'
import { Col, Container, Row } from 'react-bootstrap'

const Experience = () => {
    return (
        <Container fluid id={'experience'} className={'bg-dark'}> 
            <Container fluid={'lg'} className='page'>
                <Row className={'h-10 mx-5 text-light align-items-center fs-2 fw-bold justify-content-center'}>
                    Experience
                </Row>
                <Row className={'h-10 mx-5 text-light fs-3  d-flex justify-content-center'}>
                    <Col md={6} className='text-center align-self-center'>
                        School Project
                    </Col>
                    <Col md={6} className='text-center align-self-center'>
                        {/* self prj */}
                    </Col>
                </Row>
                <Row className={'h-70 my-3'}>
                    <SchoolPrj/>
                </Row>
            </Container>
        </Container>
    )
}

export default Experience
