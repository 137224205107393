import React from 'react'
import { Card, Button, Row } from 'react-bootstrap'
import SkillBox from '../../skill/SkillBox'

const PrjBox = (props) => {

    const prj = props.prj;
    let website = null;

    if (prj.website){
        website = <Button href={prj.website} variant="outline-info" className='ms-3' size="sm">{prj.name} Website</Button>
    }
    let relatedtech ;

    if (prj.relatedTech){
        relatedtech = "";
        prj.relatedTech.forEach(tech => {
            relatedtech = relatedtech + tech + " ";
        });
        relatedtech = relatedtech.slice(0, -1)
    }

    return (
        <Card bg='dark' text='white' className='border-0'>
            <Card.Body>
                <Card.Title className='fs-3'>{prj.name}</Card.Title>
                <Card.Subtitle className='pt-1 ps-4'>
                    {prj.date} {website}
                </Card.Subtitle>
                <br/>
                {
                    prj.descript.map((eachLine, index) => 
                        <Card.Text key={index}>
                            {eachLine}
                        </Card.Text>
                    )
                }
                <Card.Text>
                    Related Technic : <br/>
                    <Row lg={6} md={4} sm={3} className={'h-50 mx-1 py-1 text-center justify-content-center'}>
                        {
                            prj.relatedTech.map((eachSkill, index) =><SkillBox key={index} skill={eachSkill}/>)
                        }
                    </Row>
                </Card.Text>
                    
            </Card.Body>
        </Card>
    )
}

export default PrjBox
