import React from 'react'
import { Navbar, Container, Nav } from "react-bootstrap"

//fixed-top
const SelfNavbar = () => {
    return (
        <Navbar fluid={'lg'} bg="dark" variant="dark" className='py-3 fs-5'>
            <Container>
                <Navbar.Brand href="#welcome" className='fs-3'>Justin Siu</Navbar.Brand>
                <Nav className="me-auto">
                <Nav.Link href="#about">About</Nav.Link>
                <Nav.Link href="#experience">Experience</Nav.Link>
                <Nav.Link href="#skill">Skill</Nav.Link>
                <Nav.Link href="#contact">Contact</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default SelfNavbar