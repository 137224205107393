import React from 'react'
import { Icon } from '@iconify/react';  //https://iconify.design/
import { Container, Row } from 'react-bootstrap';

const SkillBox = (props) => {

    const skill = props.skill;
    let icon = null;
    if (skill.icon)
    {
        icon = <Icon icon={skill.icon} className='align-items-center w-auto h-100'/>
    } 
    
    return (
        <Container className='m-3'>
            <Row className='h-65 justify-content-center'>
                {icon}
            </Row>
            <Row className='h-25 fs-5 justify-content-center'>
                {skill.name}
            </Row>
        </Container>
    )
}

export default SkillBox
