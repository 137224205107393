import { React } from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import { Icon } from '@iconify/react'


const Welcome = () => {
    return (
        <Container fluid id={'welcome'} className={'bg-dark text-white'}> 
            <Container fluid={'lg'} className={'page'}> 
                <Row className={'h-70'}>
                    <Col className={'justify-content-lg-center  text-center'}>
                        <Row className={'h-45'}>
                            <Col className={'d-flex align-items-end fw-bold fs-1 text-uppercase text-end text-lg-start '}>
                                Hello!!
                            </Col>
                        </Row>
                        <Row className={'h-10'}>
                            <Col className={'fw-bold fs-1 text-uppercase text-end text-lg-center '}>
                                My name is <span className={"fw-bolder text-primary text-uppercase"}>Justin Siu</span>
                            </Col>
                        </Row>
                        <Row className={'h-45'}>
                            <Col className={'fw-bold fs-1 text-uppercase'}>
                                Web Developer
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={'h-30'}>
                    <Col className={'justify-content-lg-center mx-auto text-center'}>
                        <Button variant="primary " href="#about">
                            <Icon id='downArrow' icon="fluent:arrow-circle-down-double-20-regular" />
                                &nbsp; About Me &nbsp;
                            <Icon id='downArrow' icon="fluent:arrow-circle-down-double-20-regular" />
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Container>
    )   
}



export default Welcome