import React from 'react';
import ReactDOM from 'react-dom';
import SelfNavbar from './components/welcome/SelfNavbar';
import Welcome from './components/welcome/Welcome';
import Experience from './components/experience/Experience';
import About from './components/about/About';
// import Footer from './components/footer/Footer';
import Skill from './components/skill/Skill';
import Contact from './components/contact/Contact';

import './index.css';
import './ver-p.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';

// import reportWebVitals from './reportWebVitals';


const App = () => {
  return (
    <Container fluid className={'px-0 bg-dark'}>
      <SelfNavbar/>
      <Welcome/>
      <About/>
      <Experience/>
      <Skill/>
      <Contact/>
      {/* <Footer/> */}
    </Container>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
