import { React } from "react"
import { Tab, Row, ListGroup, Col } from 'react-bootstrap'
import PrjBox from "./PrjBox"


const prjList = [
    {
        'name': 'EZ PARK',
        'date' : 'Sep 2020 - Dec 2020',
        'descript': 
            ['Developed a parking ticket application using React and React Native for users to pay for parking space by scanning a QR code',
            '• The application deploy on Google Cloud Platform(GCP) and using MongoDB to keep the data in the back-end server',
            '• The web application generate QR codes for parking lots allow users to pay by scanning with their phone'],
        'relatedTech': [
            {
                'name': 'Node.js',          'icon' : 'logos:nodejs-icon',
            },
            {
                'name': 'Express.js',
            },
            {
                'name': 'GCP',           'icon' : 'logos:google-cloud',
            },
            {
                'name': 'React',           'icon' : 'vscode-icons:file-type-reactjs',
            },
            {
                'name': 'React Native',           'icon' : 'vscode-icons:file-type-reactjs',
            },
            {
                'name': 'MongoDB',           'icon' : 'logos:mongodb',
            }
            ]
    },
    {
        'name': 'Covid-19 Data Analysis',
        'date' : 'Sep 2020 - Dec 2020',
        'descript': 
            ['• Aggregated Covid-19 data and build two classifier models (AdaBoost and Random forest) from scikit-learn to predict the deceased and recovered rate',
            '• Used RandomSearchCV from scikit-learn to optimize the parameter of both models and compare the models based on their accuracy'],
        'relatedTech': [
                {
                    'name': 'Python',           'icon' : 'vscode-icons:file-type-python',
                },
                {
                    'name': 'Numpy',           'icon' : 'logos:numpy',
                },
                {
                    'name': 'Panda',           'icon' : 'simple-icons:pandas',
                },
                {
                    'name': 'Scikit-learn',           'icon' : "simple-icons:scikitlearn" ,
                }
            ]
    },
    {
        'name': 'Health Inspection Viewer',
        'date' : 'Feb 2020 - Apr 2020',
        'descript': 
            ['• An Android application using Java language to view the restaurant inspection located in the Fraser Health area',
            '• Setting up a regular update on the inspection dataset and allow users to check if there are any violated restaurants around',
            '• Using Google map API to show each restaurant with inspection detail and track user location with GPS'],
        'relatedTech': [
            {
            'name': 'Android Studio',           'icon' : 'mdi:android-studio',
            }
            ],
    },
    {
        'name': 'Treat or Trick',
        'date' : 'Jan 2020 - Feb 2020',
        'descript': 
            ['• Build an Android application using Java language to create a game for kids to have fun by finding out the hiding candy',
            '• Learning in simple animation, saving the state of the application and Dynamic Board UI layout',
            '• Using Singleton method for the game model to keep the application contain one game at a time'],
            'relatedTech': [
                {
                'name': 'Android Studio',           'icon' : 'mdi:android-studio',
                }
                ],
        },
    {
        'name': 'Burning+',
        'date' : 'Sep 2020 - Dec 2020',
        'website' : 'https://paradox-project.herokuapp.com/burning-plus.html',
        'descript': 
            ['• A web app with Heroku and provide a platform to help people getting a healthier lifestyle',
            '• Suggest a workout routine for users based on their choice to gaining muscles and achieving a better physique',
            '• Using API to check the calories with user\'s daily diet and help them track the change of calories'],
        'relatedTech': [
                {
                    'name': 'Node.js',          'icon' : 'logos:nodejs-icon',
                },
                {
                    'name': 'Express.js',    
                },
                {
                    'name': 'Heroku',           'icon' : 'logos:heroku',
                },
                {
                    'name': 'PostgreSQL',           'icon' : 'logos:postgresql',
                }
                ]
    }
]

const SchoolPrj = () => {

    return (
        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#sp1">
            <Row>
                <Col sm={4}>
                    <ListGroup className="h-80 my-2 overflow-auto">
                        <ListGroup.Item action href="#sp1">
                            <p className='fs-5 fw-bold'>EZ PARK</p>
                            - Parking application with website &amp; mobile app
                        </ListGroup.Item>
                        <ListGroup.Item action href="#sp2">
                            <p className='fs-5 fw-bold'>Covid-19 Data Analysis</p>
                            - Data Analysis using python
                        </ListGroup.Item>
                        <ListGroup.Item action href="#sp3">
                            <p className='fs-5 fw-bold'>Health Inspection Viewer</p>
                            - Android app with Java
                        </ListGroup.Item>
                        <ListGroup.Item action href="#sp4">
                            <p className='fs-5 fw-bold'>Treat or Trick</p>
                            - Game with android app using Java
                        </ListGroup.Item>
                        <ListGroup.Item action href="#sp5">
                            <p className='fs-5 fw-bold'>Burning+</p>
                            - Health tracking web application
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col sm={8}>
                    <Tab.Content >
                        <Tab.Pane eventKey="#sp1">
                            <PrjBox prj={prjList[0]}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#sp2">
                            <PrjBox prj={prjList[1]}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#sp3">
                            <PrjBox prj={prjList[2]}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#sp4">
                            <PrjBox prj={prjList[3]}/>      
                        </Tab.Pane>
                        <Tab.Pane eventKey="#sp5">
                            <PrjBox prj={prjList[4]}/>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )   
}



export default SchoolPrj