import React from 'react'
import { Container, Row } from 'react-bootstrap'

import SkillBox from './SkillBox'


const SkillsList = [
    {
        'name': 'HTML',           'icon' : 'vscode-icons:file-type-html',
    },
    {
        'name': 'CSS',             'icon' : 'vscode-icons:file-type-css',
    },
    {
        'name': 'Python',          'icon' : 'vscode-icons:file-type-python',
    },
    {
        'name': 'React',           'icon' : 'ri:reactjs-line',
    },
    {
        'name': 'SQL',             'icon' : 'vscode-icons:file-type-sql',
    },
    {
        'name': 'Node.js',         'icon' : 'logos:nodejs-icon',
    },
    {
        'name': 'Git',             'icon' : 'logos:git-icon',
    },
    {
        'name': 'Android Studio',  'icon' : 'mdi:android-studio',
    },
    {
        'name': 'GCP',             'icon' : 'logos:google-cloud',
    },
    {
        'name': 'Java',            'icon' : 'logos:java',
    },
    {
        'name': 'Bootstrap',       'icon' : 'logos:bootstrap',
    }
]


const Skill = () => {
    return (
        <Container fluid id={'skill'} className={'bg-lightsteelblue'}>
            <Container fluid={'lg'} className={'h70page'}>
                <Row className={'h-20 mx-5 align-items-center fs-2 fw-bold justify-content-center'}>
                    My Skills
                </Row>
                <Row lg={6} md={4} sm={3} className={'h-80 mx-5 py-5 text-center justify-content-center'}>
                    {
                        SkillsList.map((eachSkill, index) =><SkillBox key={index} skill={eachSkill}/>)
                    }
                </Row>
            </Container>
        </Container>
    )
}

export default Skill
