import React from 'react'
import { Card, Col } from 'react-bootstrap'


const AcademicBox = (props) => {

    const academic = props.academic
    let descript = null
   
    if (academic.descript){
        descript = <Card.Text className='fs-6'>- {academic.descript}</Card.Text>
    }
      

    return (
        <Col md={6} className='mb-2 h-65 justify-content-center'>
            <Card border='primary' bg='dark' text='white' className='mb-3 h-100'>
                <Card.Body>
                    <Card.Title className='fs-3'>{academic.School}</Card.Title>
                    <Card.Subtitle className='pt-2 ps-4'>
                        {academic.date}
                    </Card.Subtitle>
                    <Card.Text className='pt-2 ps-2 fs-6'>
                        Program : <br/> &ensp; { academic.program }
                    </Card.Text>
                    {
                        descript
                    }

                </Card.Body>
            </Card>
        </Col>
    )
}

export default AcademicBox
