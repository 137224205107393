import { React } from "react"
import Academic from "./academic/Academic"

import resume from "../files/Resume_JustinSiu.pdf"

import { Container, Row, Col, Button } from "react-bootstrap"

const About = () => {
    return  (
        <Container fluid id={'about'} className={'bg-lightsteelblue'}>
            <Container fluid={'lg'} className={'page'}>
                <Row className={'h-10 mx-5 align-items-center fs-2 fw-bold justify-content-center'}>
                    About Me
                </Row>
                <Row className={'h-30 mx-5 align-items-center fs-4 justify-content-center '}>
                    <Col lg={8} className={'text-justify px-4'}>
                        Recent graduate with computer science major.
                        Seeking a role that will allow me to use my knowledge in the field of the software development area.
                        Motivated to learn more and passionate about mobile application and web development.
                    </Col>
                    <Col lg={4}>
                        <Row className={'fs-4 fw-bold justify-content-center pb-3'}>
                            My Resume
                        </Row>
                        <Row  className={'justify-content-center'}>
                            <Button variant="success" href={resume} target="_blank">
                                    Click Here to view 
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <Row className={'h-10 mx-5 align-items-center fs-2 fw-bold justify-content-center'}>
                    Academic
                </Row>
                <Row className={'h-50'}>
                    <Academic/>
                </Row>
            </Container>
        </Container>
    )
}


export default About


